<template>
  <div class="page1">
    <canvas id="canvas" width="160" height="160" style="display:none"></canvas>
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div>
        <div class="qr-header">
          <el-row>
            <el-col :span="16">
              <h1 class="location">{{location}}</h1>
            </el-col>
            <el-col :span="8">
              <el-button @click="back">返回</el-button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <exportFile style="display: inline-block" :exportData="exportData" action="导出全部二维码"></exportFile>
            </el-col>
          </el-row>
        </div>
        <div class="qr-income">
          <h2 class="title">
            <div class="title_icon"></div>入场无牌车二维码
          </h2>
          <el-table :data="incomeTableData" style="width: 99%" :border="true">
            <el-table-column
              v-for="(item ,index) in incomeTable"
              :key="index+'income'"
              :label="item.label"
              :prop="item.prop"
              :width="item.width"
              :align="'center'"
            ></el-table-column>
            <el-table-column prop="address" label="操作" :align="'center'">
              <template slot-scope="scope">
                <el-button
                  type="warning"
                  @click="copyLink(scope.row)"
                  class="qrbtn1"
                  :data-clipboard-text="scope.row.url"
                >复制链接</el-button>
                <el-button type="warning" @click="exportQr(scope.row)">导出二维码</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="qr-outer">
          <h2 class="title">
            <div class="title_icon"></div>出场缴费二维码
          </h2>
          <el-table :data="outTableData" style="width: 99%" :border="true">
            <el-table-column
              v-for="(item, index) in outTable"
              :key="index+'out'"
              :label="item.label"
              :align="'center'"
              :prop="item.prop"
              :width="item.width"
            ></el-table-column>
            <el-table-column prop="address" label="操作" :align="'center'">
              <template slot-scope="scope">
                <el-button
                  type="warning"
                  @click="copyLink(scope.row) "
                  class="qrbtn2"
                  :data-clipboard-text="scope.row.url"
                >复制链接</el-button>
                <el-button type="warning" @click="exportQr(scope.row)">导出二维码</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="qr-income">
          <h2 class="title">
            <div class="title_icon"></div>场中缴费二维码
          </h2>
          <el-table :data="parkingData" style="width: 99%" :border="true">
            <el-table-column
              v-for="(item ,index) in parking"
              :key="index+'parking'"
              :label="item.label"
              :prop="item.prop"
              :width="item.width"
              :align="'center'"
            ></el-table-column>
            <el-table-column prop="address" label="操作" :align="'center'">
              <template slot-scope="scope">
                <el-button
                  type="warning"
                  @click="copyLink(scope.row)"
                  class="qrbtn3"
                  :data-clipboard-text="scope.row.url"
                >复制链接</el-button>
                <el-button type="warning" @click="exportQr(scope.row)">导出二维码</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <!--列表区域-->

      <!--分页器-->
      <!-- <div class="pagerWrapper">
        <div class="block">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>-->
    </div>
  </div>
</template>

<script>
import exportFile from "@/components/exportFileCommon/exportFile.vue";
import { dateFormat } from "@/common/js/public.js";
import QRCode from "qrcode";

export default {
  name: "outQrCodeDownload",
  data() {
    return {
      exportData: {},
      parkId: "",
      location: "宣武门外大街停车场",
      incomeTable: [
        {
          label: this.$t("list.park_name"),
          prop: "parkName",
          width: "150",
        },
        {
          label: this.$t("list.Channel_name"),
          prop: "parkChannelName",
          width: "150",
        },
        {
          label: "链接地址",
          prop: "url",
        },
      ],
      outTable: [
        {
          label: this.$t("list.park_name"),
          prop: "parkName",
          width: "150",
        },
        {
          label: this.$t("list.Channel_name"),
          prop: "parkChannelName",
          width: "150",
        },
        {
          label: "链接地址",
          prop: "url",
        },
      ],
      parking: [
        {
          label: this.$t("list.park_name"),
          prop: "parkName",
          width: "150",
        },
        {
          label: "链接地址",
          prop: "url",
        },
      ],
      incomeTableData: [],
      outTableData: [],
      parkingData: [],
    };
  },
  watch: {},
  methods: {
    searchData() {
      this.$axios
        .get("/acb/2.0/bacb/qrCode/list", {
          data: {
            parkId: this.parkId, // 车场ID
          },
        })
        .then((res) => {
          this.location = res.value[0].parkName;
          this.exportData = {
            url: "/acb/2.0/bacb/qrCode/exportAll",
            methods: "get",
            data: {
              parkId: this.parkId, // 车场ID
              fileName: this.location,
              name: this.location,
            },
          };
          this.incomeTableData = res.value.filter(
            (item) => item.qrCodeType == 1
          );
          this.outTableData = res.value.filter((item) => item.qrCodeType == 2);
          this.parkingData = res.value.filter((item) => item.qrCodeType == 3);
        });
    },
    getBrowser() {
      const userAgent = window.navigator.userAgent;
      if (userAgent.indexOf("OPR") > -1) {
        return "Opera";
      }
      if (userAgent.indexOf("Firefox") > -1) {
        return "FF";
      }
      if (userAgent.indexOf("Trident") > -1) {
        return "IE";
      }
      if (userAgent.indexOf("Edge") > -1) {
        return "Edge";
      }
      if (userAgent.indexOf("Chrome") > -1) {
        return "Chrome";
      }
      if (userAgent.indexOf("Safari") > -1) {
        return "Safari";
      }
    },
    exportQr(row) {
      const self = this;
      QRCode.toCanvas(
        document.getElementById("canvas"),
        row.url,
        {
          width: 160,
          height: 160,
        },
        function (error) {
          if (error) console.error(error);
          const image = document
            .getElementById("canvas")
            .toDataURL("image/png");
          const browser = self.getBrowser();
          if (browser === "IE" || browser === "Edge") {
            let REGSTR_IE = /trident\/[\d.]+/gi;
            if (
              parseInt(navigator.userAgent.match(REGSTR_IE)[0].split("/")[1]) <=
              5
            ) {
              self.$alert("浏览器版本太低！");
              return;
            }
            window.navigator.msSaveBlob(
              self.convertBase64UrlToBlob(image),
              row.parkName +
                "-" +
                (row.parkChannelName || "场中支付") +
                "二维码.png"
            );
          } else if (browser === "Chrome") {
            const saveLink = document.createElement("a");
            saveLink.href = "data:application/octet-stream;base64" + image;
            saveLink.download =
              row.parkName +
              "-" +
              (row.parkChannelName || "场中支付") +
              "二维码.png";
            saveLink.click();
          } else {
            const saveLink = document.createElement("a");
            let evt = document.createEvent("HTMLEvents");
            evt.initEvent("click", true, true); //  initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
            saveLink.download =
              row.parkName +
              "-" +
              (row.parkChannelName || "场中支付") +
              "二维码.png";
            saveLink.href = URL.createObjectURL(self.convertBase64UrlToBlob(image));
            saveLink.dispatchEvent(
              new MouseEvent("click", {
                bubbles: true,
                cancelable: true,
                view: window,
              })
            ); //  兼容火狐
          }
        }
      );
    },
    convertBase64UrlToBlob(base64) {
      const parts = base64.split("base64,");
      const contentType = parts[0].split(":")[1];
      const raw = window.atob(parts[1]);
      const rawLength = raw.length;
      const uInt8Array = new Uint8Array(rawLength);
      for (let i = 0; i < rawLength; i++) {
        uInt8Array[i] = raw.charCodeAt(i);
      }
      return new Blob([uInt8Array], { type: contentType });
    },

    exportAllQr() {
      this.$axios
        .get("/acb/2.0/bacb/qrCode/exportAll", {
          data: {
            parkId: this.parkId, // 车场ID
            fileName: this.parkName,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            window.open(res.value.fileUrl);
          } else {
            this.$alert(res.desc);
          }
        });
      // /acb/2.0/bacb/qrCode/exportAll
    },
    // 返回
    back() {
      this.$router.go(-1);
    },
    // 复制链接
    copyLink(link) {},
  },
  beforeDestroy() {},
  components: {
    exportFile,
  },
  activated() {
    this.searchData();
  },
  created() {
    // this.parkId = 1;
    this.parkId = this.$route.query.parkId;
    this.searchData();
    // this.queryTypeList();
    // this.getTenantList();
  },
  computed: {},
  updated() {},
  mounted() {
    if (this.$route.meta.authority) {
      let data = {};
      if (sessionStorage.getItem("authority")) {
        data = JSON.parse(sessionStorage.getItem("authority"));
      }
      data["parkList"] = this.$route.meta.authority;
      sessionStorage.setItem("authority", JSON.stringify(data));
    }
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.qr-header {
  .location {
    line-height: 60px;
  }

  height: 50px;
  line-height: 50px;
  border-bottom: 2px solid #e6a23c;
}

.qr-income {
  margin-top: 20px;
  text-align: left;
}

.qr-outer {
  margin-top: 50px;
  text-align: left;
}
</style>
